.sidebar{
    background-color: var(--sidebar-bg);
    height: 100%;
    width: 250px;
    position: fixed;
}

.sidebar h1{
    font-family: var(--font-lato);
    font-weight: 700;
    font-size: 49px;
    line-height: 59px;
    text-align: center;
    color: var(--white);
    padding-top: 40px;
    padding-bottom: 40px;
}

.sidebar h4{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    color: #A9B5BC;
}

.sidebar-menu-container{
    display: flex;
    flex-direction: column;
    padding-left: 25px;
}

.sidebar-menu{
    display: block;
    width: 183px;
    height: 50px;
    background: var(--sidebar-bg);
    font-family: var(--font-lato);
    font-weight: 400;
    font-size: 24px;
    line-height: 50px;
    color: var(--white);
    padding-left: 10px;
}

.active{
    background: #33415F;
}